// third-party
import { createSlice } from '@reduxjs/toolkit';

// this is the case when the user needs to reset session - clear cache and stuff.

// ----------------------------------------------------------------------

const initialState = {
    plsResetSession: true,
    languageShowing: 'en',
    languageWasSetManually: false,
};

const session = createSlice({
    name: 'session',
    initialState,
    reducers: {
        resetUserSession(state, action) {
            state.plsResetSession = action.payload;
        },
        setLanguageShowing(state, action) {
            state.languageShowing = action.payload;
        },
        setLanguageWasSetManually(state, action) {
            state.languageWasSetManually = action.payload;
        },
    },
});

// Reducer
export default session.reducer;
export const {
    resetUserSession,
    setLanguageShowing,
    setLanguageWasSetManually,
} = session.actions;

// ----------------------------------------------------------------------
