// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import axios from 'axios';
import { getApiUrl } from 'helpers';
// import { dispatch } from '../index';
// import { useDispatch } from 'react-redux';

// ----------------------------------------------------------------------
const initialSchedule = [
    {
        dow: 1,
        dowLabel: 'Monday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
    {
        dow: 2,
        dowLabel: 'Tuesday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
    {
        dow: 3,
        dowLabel: 'Wednesday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
    {
        dow: 4,
        dowLabel: 'Thursday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
    {
        dow: 5,
        dowLabel: 'Friday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
    {
        dow: 6,
        dowLabel: 'Saturday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
    {
        dow: 0,
        dowLabel: 'Sunday',
        startTime: '07:00',
        endTime: '17:00',
        formattedStart: '7am',
        formattedEnd: '5pm',
        disabled: false,
    },
];

const initialState = {
    studioEmployeeId: 0,
    isAlsoInstructor: false,
    instructorId: 0,
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
    accountType: 'unassociated-user',
    role: 'any-user',
    permissions: [],
    financialAccess: false,
    settingsAccess: false,
    profilePicture: '',
    dibsStudioId: 0,
    managesFullSchedule: true,
    doneLoading: false,
    isLoggedIn: false,
    admin: false,
    instructor_only: false,
    alreadyHasAccount: false,
    lastLocation: '',
    availability: initialSchedule,
    availabilityLoaded: false,
    prepMinutes: 0,
    availabilityExceptions: [],
    version: '3.4.5',
};

const studioemployeeuser = createSlice({
    name: 'studioemployeeuser',
    initialState,
    reducers: {
        setThisUserEmail(state, action) {
            state.email = action.payload;
        },
        setIsAlsoInstructor(state, action) {
            state.isAlsoInstructor = action.payload;
        },
        setAvailabilityExceptions(state, action) {
            state.availabilityExceptions = action.payload;
        },
        setPrepMinutes(state, action) {
            state.prepMinutes = action.payload;
        },
        setInstructorId(state, action) {
            state.instructorId = action.payload;
        },
        setEmployeeAvailability(state, action) {
            state.availability = action.payload;
        },
        setEmployeeAvailabilityLoaded(state, action) {
            state.availabilityLoaded = action.payload;
        },
        setThisEmployeeIsAdmin(state, action) {
            state.admin = action.payload;
        },
        setThisEmployeeIsInstructorOnly(state, action) {
            state.instructor_only = action.payload;
        },
        setIsLoggedIn(state, action) {
            state.isLoggedIn = action.payload;
        },
        setStudioProfileAccountInfo(state, action) {
            state.firstname = action.payload.firstname;
            state.lastname = action.payload.lastname;
            state.email = action.payload.email;
            state.phone = action.payload.phone;
        },
        setThisUserRole(state, action) {
            state.role = action.payload;
        },
        setThisUserDoneLoading(state, action) {
            state.doneLoading = action.payload;
        },
        setThisUserPermissions(state, action) {
            state.permissions = action.payload;
        },
        setThisUserEmployeeId(state, action) {
            state.studioEmployeeId = action.payload;
        },
        setThisUserFirstName(state, action) {
            state.firstname = action.payload;
        },
        setAlreadyHasAccount(state, action) {
            state.alreadyHasAccount = action.payload;
        },
        setThisUserDibsStudioId(state, action) {
            const newdibsId = action.payload;
            const newDibsIdAsNum = parseInt(newdibsId, 10);
            console.log('\n\n\nnew dibs id as number is', newDibsIdAsNum);
            state.dibsStudioId = newDibsIdAsNum;
        },
        setThisUserLastName(state, action) {
            state.lastname = action.payload;
        },
        setThisUserPhone(state, action) {
            state.phone = action.payload;
        },
        setHasFinancialAccess(state, action) {
            state.financialAccess = action.payload;
        },
        setHasSettingsAccess(state, action) {
            state.settingsAccess = action.payload;
        },
        setManagesSchedule(state, action) {
            state.managesFullSchedule = action.payload;
        },
        setThisUserProfilePicture(state, action) {
            state.profilePicture = action.payload;
        },
        setThisUserAccountType(state, action) {
            state.accountType = action.payload;
        },
        setHasSetPwdFirebase(state, action) {
            return {
                ...state,
                hasSetPwdFirebase: action.payload,
            };
        },
        setCurrentStep(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                currentStep: action.payload,
            };
        },
        clearThisUser(state, action) {
            state.studioEmployeeId = 0;
            state.email = '';
            state.firstname = '';
            state.lastname = '';
            state.phone = '';
            state.accountType = 'unassociated-user';
            state.role = 'any-user'; // 'admin', 'employee', 'instructor', 'any-user', 'dibs-super-admin'
            state.permissions = [];
            state.financialAccess = false;
            state.settingsAccess = false;
            state.profilePicture = '';
            state.dibsStudioId = 0;
            state.managesFullSchedule = true;
            state.doneLoading = false;
            state.isLoggedIn = false;
            state.isAlsoInstructor = false;
            state.instructorId = 0;
            state.admin = false;
            state.instructor_only = false;
            state.alreadyHasAccount = false;
            state.lastLocation = '';
            state.availability = initialSchedule;
            state.availabilityLoaded = false;
            state.prepMinutes = 0;
            state.availabilityExceptions = [];
        },
        setEncryptedPassword(state, action) {
            return {
                ...state,
                encryptedPassword: action.payload,
            };
        },
        setBirthday(state, action) {
            return {
                ...state,
                birthday: action.payload,
            };
        },
        setPhone(state, action) {
            return {
                ...state,
                phone: action.payload,
            };
        },
        setRecentlyLoggedIn(state, action) {
            return {
                ...state,
                recentlyLoggedIn: action.payload,
            };
        },
        setIsInLoginLinkFlow(state, action) {
            return {
                ...state,
                isInLoginFlow: action.payload,
            };
        },
    },
});

// Reducer
export default studioemployeeuser.reducer;
export const {
    setThisUserEmail,
    setIsInLoginLinkFlow,
    setBirthday,
    setPhone,
    setAvailabilityExceptions,
    setRecentlyLoggedIn,
    setEncryptedPassword,
    setPrepMinutes,
    setEmployeeAvailability,
    setThisUserFirstName,
    setThisUserLastName,
    setThisUserPhone,
    setInstructorId,
    setIsAlsoInstructor,
    clearThisUser,
    setThisUserEmployeeId,
    setThisUserRole,
    setThisUserPermissions,
    setHasFinancialAccess,
    setHasSettingsAccess,
    setThisUserDibsStudioId,
    setThisUserProfilePicture,
    setManagesSchedule,
    setThisUserAccountType,
    setThisUserDoneLoading,
    setIsLoggedIn,
    setStudioProfileAccountInfo,
    setEmployeeAvailabilityLoaded,
    setAlreadyHasAccount,
    setHasSetPwdFirebase,
    setCurrentStep,
    setThisEmployeeIsAdmin,
    setThisEmployeeIsInstructorOnly,
} = studioemployeeuser.actions;

// ----------------------------------------------------------------------
