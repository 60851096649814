import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Provider } from 'react-redux';
import axios from 'axios';
import { Container, Stack } from '@mui/material';
import ErrorBar from 'studio-admin/components/Layout/ErrorBar.jsx';
import ErrorSuccessAlert from 'components/error-success/ErrorAlert';
// import SuccessBar from 'studio-admin/components/Layout/SuccessBar.jsx';
import './App.css';
// import { Typography } from '@mui/material';
import { library } from '@fortawesome/fontawesome-svg-core';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UPDATE_NOTIFICATION_VERSION, somethingNew } from 'constants/variables';
import sendOpsEmail from 'actions/comm-logs/sendStandAloneOpsEmailNew';

// import AccountHeader from 'layouts/header-new';
import DibsFooter from 'components/footers/dibs-footer';

import {
    faSackDollar,
    faDollarSign,
    faPlus,
    faCheck,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faCircle,
    faChevronRight,
    faChevronLeft,
    faCreditCard,
    faX,
    faRepeat,
    faPlus as faPlusSolid,
} from '@fortawesome/pro-solid-svg-icons';
import {
    faUser,
    faBagShopping,
    faCalendar,
} from '@fortawesome/pro-light-svg-icons';

import {
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
} from '@fortawesome/free-brands-svg-icons';

import { store } from 'store';
// import initialTypography from 'themes/typography';
import ThemeCustomization from 'themes';
import defaultColor from 'assets/scss/_themes-vars.module.scss';

library.add(
    faBagShopping,
    faSackDollar,
    faDollarSign,
    faCircle,
    faPlusSolid,
    faUser,
    faCalendar,
    faChevronRight,
    faPlus,
    faChevronLeft,
    faCheck,
    faCcVisa,
    faCcMastercard,
    faCcAmex,
    faCcDiscover,
    faCreditCard,
    faX,
    faRepeat
);

const originalthemespassedin = ThemeCustomization();

let theme = createTheme(originalthemespassedin); // come back to this
const colors = defaultColor;

const Components = {
    MuiInputBase: {
        styleOverrides: {
            root: {
                borderRadius: 4,
                fontWeight: 300,
                fontSize: '0.875rem',
                lineHeight: '1rem',
                // paddingTop: 80.25,
                // padding: 3,
                color: theme.palette.text.dark,
            },
            input: {
                '&.MuiOutlinedInput-input': {
                    padding: '12px',
                    '&:hover': {
                        border: 1,
                        borderColor: theme.palette.primary.light,
                    },
                },
            },
        },
    },
    // MuiIconButton: {
    //     styleOverrides: {
    //         root: {
    //             paddingRight: '2px',
    //             paddingLeft: '2px',
    //         },
    //     },
    // },
    // MuiInputAdornment: {
    //     styleOverrides: {
    //         root: {
    //             marginLeft: '3px',
    //         },
    //     },
    // },
    MuiSelect: {
        ...theme.components.MuiSelect,
        styleOverrides: {
            ...theme.components.MuiSelect.styleOverrides,
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                },
            },
        },
    },
    MuiMenu: {
        styleOverrides: {
            root: {
                maxHeight: '275px',
            },
        },
    },

    MuiLink: {
        styleOverrides: {
            root: {
                // color: colors.primaryDark,
                '&.MuiLink-secondary': {
                    // Targeting links with `variant="secondary"`
                    color: '#ff6900',
                },
            },
        },
    },
    MuiButtonBase: {
        defaultProps: {
            elevation: 0,
        },
        ...theme.components.MuiButtonBase,
        styleOverrides: {
            root: {
                lineHeight: 1.2,
                ...theme.components.MuiButton,
            },
            // input: {
            //     padding: '5px',
            // },
        },
    },
};

theme = createTheme({
    ...theme,
    components: {
        ...theme.components,
        ...Components,
    },
});
const Typography = {
    typography: {
        subtitle1: {
            fontSize: 15,
            lineHeight: 1.7,
            color: colors.primaryDark,
        },
        subtitlelink: {
            fontSize: 15,
            lineHeight: 1.7,
            color: '#bd8d81',
        },
        subtitle2: {
            fontSize: 15,
            lineHeight: 1.7,
            fontWeight: 300,
        },
        guidance: {
            fontSize: '0.85rem',
            color: colors.primaryDark,
            fontWeight: 500,
        },
        guidance2: {
            fontSize: '0.75rem',
            color: colors.primaryDark,
            fontWeight: 400,
        },
        checkout: {
            fontSize: 15,
            lineHeight: 1.1,
        },
        leftmenu: {
            // fontStyle: "Roboto","Helvetica","Arial",sans-serif;
            fontSize: '1rem',
            fontWeight: 400,
            color: '#4b4949',
            textTransform: 'capitalize',
        },
        eventtime: {
            fontSize: '0.95rem',
            fontWeight: 500,
        },
        subeventtime: {
            fontSize: '0.70rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventlocation: {
            fontSize: '0.95rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventname: {
            fontSize: '0.95rem',
            fontWeight: 400,
        },
        bookedclients: {
            fontSize: '0.8rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        bookedclientsname: {
            fontSize: '0.8rem',
            fontWeight: 400,
        },
        eventinstructor: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#ADB5BD',
        },
        eventprice: {
            fontSize: '1.25rem',
            fontWeight: 400,
            color: '#6C757D',
        },
        headers: {
            fontSize: '1.25rem',
            fontWeight: 500,
        },
        packageNames: {
            fontSize: '0.8rem',
            fontWeight: 500,
            lineHeight: '1.2rem',
        },
        numClasses: {
            fontSize: '0.8rem',
            fontWeight: 400,
            // color: '#6C757D',
        },
        expiration: {
            fontSize: '0.6rem',
            fontWeight: 400,
            color: '#ADB5BD',
            lineHeight: '0.8rem',
            fontStyle: 'italic',
        },
        subtextItalics: {
            fontSize: '0.75rem',
            fontWeight: 400,
            color: '#ADB5BD',
            lineHeight: '1rem',
            fontStyle: 'italic',
        },
        packageSubheaders: {
            fontSize: '0.8rem',
            fontWeight: 300,
            color: '#ADB5BD',
        },
        packageClasses: {
            fontSize: '0.9rem',
            fontWeight: 400,
        },
        subMenuTitle: {
            fontSize: 15,
            lineHeight: 1.7,
            fontWeight: 500,
        },
        regularText: {
            fontSize: 14,
            lineHeight: 1.7,
            fontWeight: 400,
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 500,
        },
        packageSummary: {
            fontSize: '0.75rem',
            lineHeight: '1.4rem',
        },
        packageSummaryBold: {
            fontSize: '0.75rem',
            lineHeight: '1.4rem',
            fontWeight: 500,
        },
        hidden: {
            fontSize: '0.8rem',
            color: '#fff',
        },
        ccCardNumberText: {
            fontSize: '0.65rem',
            color: '#fff',
        },
        // h5: {
        //     fontSize: '0.85rem',
        //     fontWeight: 400,
        //     marginBottom: '0.5rem',
        // },
        creditcard: {
            fontSize: '0.8rem',
            fontWeight: 300,
        },
        creditcardSmall: {
            fontSize: '0.7rem',
            fontWeight: 300,
            lineHeight: '0.75rem',
        },
        expireTextTiny: {
            fontSize: '0.6rem',
            fontWeight: 300,
            lineHeight: '0.75rem',
        },
        errorText: {
            fontSize: '0.8rem',
            fontWeight: 500,
            color: colors.errorMain,
        },
        successText: {
            fontSize: '0.8rem',
            fontWeight: 500,
            color: colors.successMain,
        },
        boldHeader: {
            fontSize: '0.85rem',
            fontWeight: 600,
            color: colors.grey650,
        },
        lightHeader: {
            fontSize: '0.85rem',
            fontWeight: 300,
            color: colors.grey650,
        },
        regHeader: {
            fontSize: '0.85rem',
            fontWeight: 400,
            color: colors.grey650,
        },
        packageUsed: {
            fontSize: '0.85rem',
            fontWeight: 500,
            color: colors.grey400,
        },
    },
};
theme = createTheme({
    ...theme,
    typography: {
        ...theme.typography,
        ...Typography.typography,
    },
});

function App() {
    let { dibsId } = useParams();
    localStorage.setItem('dibsId', dibsId);

    const [primaryColor, setPrimaryColor] = React.useState('#eeeeee');
    // const [dibsWidth, setDibsWidth] = React.useState('98vw');
    const dibsWidth = '100vw';
    React.useEffect(() => {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const basicurl = `${baseURL}/widget/get-basic-config`;
        const dibsIdNum = parseInt(dibsId, 10);
        const getColor = async () => {
            await axios
                .post(basicurl, { dibsStudioId: dibsIdNum })
                .then((response) => {
                    // const colortosend = `#${response.data.color}`;
                    const studioColorDefault = '#e7b2a5';
                    setPrimaryColor(studioColorDefault);
                });
        };

        getColor();
    }, [dibsId]);
    React.useEffect(() => {
        const handlePageShow = (event) => {
            if (!event.persisted) {
                // window.location.reload();

                localStorage.setItem(
                    'hasSeenUpdateNotificationVersion',
                    UPDATE_NOTIFICATION_VERSION
                );
                localStorage.setItem('userRefreshed', 'true');
                localStorage.setItem('hasSeenUpdateNotification', 'true');
                localStorage.setItem('hasSeenSecondNotification', 'true');
                const dibsIdInfo = localStorage.getItem(
                    'persist:studioemployeeuser'
                );
                sendOpsEmail({
                    userid: 'n/a',
                    dibsId: 'n/a - check info',
                    eventid: 'n/a',
                    typeofevent: 'refreshed-screen',
                    subject: 'User Refreshed Screen',
                    relevantId: 'n/a - user took action',
                    idStringName: 'refreshed-screen',
                    action: 'viewed',
                    extraInfo: `User refreshed the screen to get the new version --> Version ${UPDATE_NOTIFICATION_VERSION} and more info is ${dibsIdInfo}`,
                    studioEmployeeId: 'check above',
                    fromWhere: 'studio-admin',
                });
            }
        };
        const lastVersionSeen = localStorage.getItem(
            'hasSeenUpdateNotificationVersion'
        );
        const isCurrent = lastVersionSeen === UPDATE_NOTIFICATION_VERSION;
        if (somethingNew && !isCurrent) {
            window.addEventListener('pageshow', handlePageShow);
        }

        return () => {
            window.removeEventListener('pageshow', handlePageShow);
        };
    }, []);
    theme = createTheme({
        ...theme,
        palette: {
            ...theme.palette,
            // primary: {
            //     ...theme.palette.primary,
            //     main: '#e7b2a5',
            // },
            text: {
                ...theme.palette.secondary,
                primary: colors.grey700,
                secondary: colors.grey500,
                dark: colors.grey900,
                // hint: colors.grey100,
            },
            common: {
                black: colors.darkPaper,
                lightgrey: colors.grey300,
                border: colors.greyborder,
            },
            profile: {
                ...theme.palette.profile,
                main: colors.primary300,
            },
            error: {
                main: colors.errorMain,
            },
            success: {
                main: colors.successMain,
            },
            contrastThreshold: 3,
            tonalOffset: 0.4,
        },
    });
    theme = createTheme({
        ...theme,
        typography: {
            ...theme.typography,
            coloredHeader: {
                fontSize: 14,
                lineHeight: 1.7,
                fontWeight: 500,
                color: primaryColor,
            },
            creditDisplay: {
                fontSize: 18,
                lineHeight: 1.7,
                fontWeight: 500,
                color: primaryColor,
            },
        },
    });
    theme = createTheme({
        ...theme,
        components: {
            ...theme.components,
            // MuiTouchRipple: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: primaryColor,
            //         },
            //     },
            // },
            // MuiButton: {
            //     ...theme.components.MuiButton,
            //     styleOverrides: {
            //         root: theme.components.MuiButton.styleOverrides.root,
            //         outlined: {
            //             color: theme.palette.primary.main,
            //         },
            //         // checkout: {
            //         //     backgroundColor: primaryColor,
            //         //     '&:hover': {
            //         //         backgroundColor: theme.palette.primary.light,
            //         //     },
            //         // },
            //         checkoutinverse: {
            //             backgroundColor: '#fff',
            //             color: primaryColor,
            //             borderColor: primaryColor,
            //             '&:hover': {
            //                 backgroundColor: theme.palette.primary.light,
            //             },
            //         },
            //         text: {
            //             color: primaryColor,
            //         },
            //     },
            // },
            MuiButtonBase: {
                defaultProps: {
                    elevation: 0,
                },
                styleOverrides: {
                    root: {
                        lineHeight: 1.1,
                        boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
                    },
                },
            },
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        color: '#616161',
                        '&:off': {
                            color: '#d3d1d1',
                        },
                    },
                },
            },

            MuiInputBase: {
                ...theme.components.MuiInputBase,
                styleOverrides: {
                    ...theme.components.MuiInputBase.styleOverrides,
                    root: {
                        ...theme.components.MuiInputBase.styleOverrides.root,
                        borderRadius: 4,
                        color: theme.palette.text.dark,
                        '&.Mui-focused': {
                            border: 1,
                            // borderColor: theme.palette.primary.light,
                            borderColor: '#ccc',
                        },
                        '&:hover': {
                            border: 1,
                            borderColor: theme.palette.primary.light,
                        },
                        '&::placeholder': {
                            color: theme.palette.text.secondary,
                            // fontSize: '1.875rem',
                            fontWeight: 200,
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                ...theme.components.MuiOutlinedInput,
                styleOverrides: {
                    ...theme.components.MuiOutlinedInput.styleOverrides,
                    root: {
                        ...theme.components.MuiOutlinedInput.styleOverrides
                            .root,

                        '&.Mui-focused': {
                            border: 1,
                            borderColor: theme.palette.primary.light,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            ...theme.components.MuiOutlinedInput.styleOverrides
                                .root['& .MuiOutlinedInput-notchedOutline'],
                            borderColor: 'grey', // Setting the border color to red
                            borderWidth: '1px', // Setting the border width
                            // You can add more style rules here
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            ...theme.components.MuiOutlinedInput.styleOverrides
                                .root[
                                '&:hover .MuiOutlinedInput-notchedOutline'
                            ],
                            borderColor: '#bd8d81', // Change the border color on hover
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            ...theme.components.MuiOutlinedInput.styleOverrides
                                .root[
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline'
                            ], // example of how to change the border color
                            borderColor: '#bd8d81', // Change the border color when the component is focused
                            borderWidth: '1px', // Optionally increase the border width when focused
                        },
                    },
                    input: {
                        // This targets the input part within any MuiOutlinedInput component
                        paddingRight: '2px !important', // CHECK HERE IF THERE IS A PROBLEM - CHANGED ON 5/29/24
                    },
                },
            },
        },
    });

    return (
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <CSSTransition
                        in={true}
                        timeout={350}
                        classNames="display"
                        unmountOnExit
                    >
                        <Stack>
                            <Container
                                disableGutters
                                maxWidth={'100vw'}
                                sx={{
                                    width: dibsWidth,
                                    height: 'auto',
                                    overflow: 'visible',
                                    // minHeight: '75vh',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // alignContent: 'flex-start',
                                }}
                            >
                                {/* <AccountHeader /> */}
                                <ErrorSuccessAlert />
                                {/* <SuccessBar /> */}
                                <Outlet />
                                <DibsFooter />
                            </Container>
                        </Stack>
                    </CSSTransition>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    );
}

export default App;
