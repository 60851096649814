import { Link, Typography, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// ==============================|| FOOTER ||============================== //

const theme = createTheme({
    typography: {
        subtitle2: {
            fontSize: '0.65rem',
            fontWeight: 400,
            color: '#999',
        },
    },
});

const DibsFooter = () => (
    <ThemeProvider theme={theme}>
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            id="dibs-footer-container"
            sx={{ mt: 4, mb: 3 }}
        >
            <Typography
                variant="subtitle2"
                component={Link}
                href="https://getondibs.com"
                target="_blank"
                underline="none"
            >
                Powered by Dibs
            </Typography>
        </Grid>
    </ThemeProvider>
);

export default DibsFooter;
