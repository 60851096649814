// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------
const initialState = {
    instructorData: [],
    isLoading: false,
    hasError: false,
    errorMsg: '',
    instructorAvailability: {
        defaultAvailability: [],
        availabilityFromDb: [
            {
                instructorid: 1,
                availability: [],
            },
            {
                instructorid: 2,
                availability: [],
            },
        ],
        allInstructorAvailability: [],
        allInstructorExceptions: [],
    },
    instructorAvailabilityHasBeenUpdated: true,
    needsResetInstructors: false,
};

const instructors = createSlice({
    name: 'instructors',
    initialState,
    reducers: {
        // HAS ERROR
        setHasError(state, action) {
            // state.hasError = false;
            // state.hasError = action.payload;
            return {
                ...state,
                hasError: action.payload,
            };
        },
        setErrorMsg(state, action) {
            return {
                ...state,
                errorMsg: action.payload,
            };
        },
        setDefaultInstructorAvailability(state, action) {
            return {
                ...state,
                instructorAvailability: {
                    ...state.instructorAvailability,
                    defaultAvailability: action.payload,
                },
            };
        },
        setNeedsResetInstructors(state, action) {
            return {
                ...state,
                needsResetInstructors: action.payload,
            };
        },
        setInstructorAvailabilityHasBeenUpdated(state, action) {
            return {
                ...state,
                instructorAvailabilityHasBeenUpdated: action.payload,
            };
        },
        setInstructorAvailability(state, action) {
            const { instructorid, availability } = action.payload;
            const holderArray =
                state.instructorAvailability.availabilityFromDb.filter(
                    (item) => {
                        return item.instructorid !== instructorid;
                    }
                );
            holderArray.push({ instructorid, availability });
            return {
                ...state,
                instructorAvailability: {
                    ...state.instructorAvailability,
                    availabilityFromDb: holderArray,
                },
            };
        },

        setAllInstructorAvailability(state, action) {
            return {
                ...state,
                instructorAvailability: {
                    ...state.instructorAvailability,
                    allInstructorAvailability: action.payload,
                },
            };
        },
        setAllInstructorExceptions(state, action) {
            return {
                ...state,
                instructorAvailability: {
                    ...state.instructorAvailability,
                    allInstructorExceptions: action.payload,
                },
            };
        },
        updateAllInstructorAvailability(state, action) {
            const { instructorid, thisInstructorAvailability } = action.payload;
            const holderArray =
                state.instructorAvailability.allInstructorAvailability.filter(
                    (item) => {
                        return item.instructorId !== instructorid;
                    }
                );
            holderArray.push(thisInstructorAvailability);
            return {
                ...state,
                instructorAvailability: {
                    ...state.instructorAvailability,
                    allInstructorAvailability: holderArray,
                },
            };
        },
        // SET SCHEDULE
        setInstructorData(state, action) {
            // state.schedule = action.payload;
            return {
                ...state,
                instructorData: action.payload,
            };
        },
        // SET LOADING
        setLoading(state, action) {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        resetInstructors(state) {
            return {
                ...state,
                instructorData: [],
                isLoading: false,
            };
        },
    },
});

// Reducer
export default instructors.reducer;
export const {
    setHasError,
    setInstructorData,
    setErrorMsg,
    setLoading,
    resetInstructors,
    setInstructorAvailability,
    setAllInstructorAvailability,
    setInstructorAvailabilityHasBeenUpdated,
    setAllInstructorExceptions,
    updateAllInstructorAvailability,
    setDefaultInstructorAvailability,
    setNeedsResetInstructors,
} = instructors.actions;

// ----------------------------------------------------------------------
