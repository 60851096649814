import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function checkPermissionsThisStudio(email, dibsId) {
    const url = getApiUrl('/employee/check-permission');
    let datatosend = {};
    const setlast = async () => {
        await axios
            .post(url, {
                email,
                dibsId,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error checking permissions for this email addy ${email} at this studio ${dibsId}: ${err}`
                );
                return err;
            });
    };
    await setlast();
    return datatosend;
}
