import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth, logInWithEmailAndPassword } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { lowercaseString } from 'helpers';
import setLastLogin from 'actions/users/setLastLogin';
import setPwdFirebaseUpdated from 'actions/users/setPwdFirebaseUpdated';
import { gridSpacing } from 'store/constant';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Grid,
    Box,
    Button,
    Checkbox,
    // Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    // Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    TextField,
    // useMediaQuery,
} from '@mui/material';

// third party
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useSelector, dispatch } from 'store';
import { setIsLoggedIn } from 'store/slices/studioemployeeuser';

// project imports
import SubCard from 'ui-component/cards/AccountSubCard';
import ErrorMessage from 'views/feedback-messages/error/errorMessage';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// import Google from 'assets/images/icons/social-google.svg';
// import { addStudioData } from 'store/slices/dibsstudio';

const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Your email address looks incorrect. Please try again')
        .required('Required'),
    pwd: yup.string().max(255).required('Password is required'),
});

// ============================|| FIREBASE - LOGIN ||============================ //

const LoginAuthenticationCard = () => {
    const { lastLocation, hasSetPwdFirebase } = useSelector(
        (state) => state.studioemployeeuser
    );
    const { dibsId } = useParams();

    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth);

    // if logged in, take them to the page they were on
    React.useEffect(() => {
        if (user && !user.displayName) {
            console.log('there is a user but no display name');
            return navigate('/register/add-profile', { replace: true });
        }
        if (user && !loading) {
            dispatch(setIsLoggedIn(true));
            if (!hasSetPwdFirebase) {
                navigate(`/account/${dibsId}`, { replace: true });
            }
            if (lastLocation?.length > 2) {
                navigate(lastLocation, { replace: true });
            } else {
                navigate(`/schedule/${dibsId}`, { replace: true });
            }
        }
        if (!hasSetPwdFirebase && !loading && !user) {
            return navigate(`/login-email-link/${dibsId}`, { replace: true });
        }
    }, [user, loading, navigate, lastLocation, dibsId, hasSetPwdFirebase]);

    const formik = useFormik({
        initialValues: {
            email: '',
            pwd: '',
        },
        validationSchema,
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                await logInWithEmailAndPassword(values.email, values.pwd).then(
                    () => {
                        const emailLwr = lowercaseString(values.email);
                        setLastLogin(emailLwr);
                        setPwdFirebaseUpdated(emailLwr);
                    }
                );
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
            }
        },
    });

    return (
        <Grid container justifyContent="center">
            <Grid item xs={10} md={6} sx={{ mb: 5 }} id="auth-login-sign-in">
                <SubCard title="Sign In">
                    <Grid container spacing={gridSpacing}>
                        <ErrorMessage />
                        <Grid item xs={12}>
                            <Typography variant="regularText">
                                Enter your email and password to sign in
                            </Typography>
                            <form
                                className="form-profile"
                                onSubmit={formik.handleSubmit}
                            >
                                <Grid container justifyContent="center">
                                    <Grid
                                        item
                                        xs={10}
                                        md={7}
                                        sx={{ mt: 3, ml: 1 }}
                                    >
                                        <Grid
                                            container
                                            spacing={1}
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{ mt: 0, mr: 1 }}
                                            >
                                                <InputLabel>Email</InputLabel>
                                                <TextField
                                                    fullWidth
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.email &&
                                                        Boolean(
                                                            formik.errors.email
                                                        )
                                                    }
                                                    helperText={
                                                        formik.touched
                                                            .friendEmail &&
                                                        formik.errors
                                                            .friendEmail
                                                    }
                                                    sx={{ mt: 1 }}
                                                >
                                                    Email
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sx={{ mt: 3, ml: 1 }}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            spacing={0}
                                        >
                                            <Grid item xs={10} md={7}>
                                                <InputLabel>
                                                    Password
                                                </InputLabel>
                                                <TextField
                                                    fullWidth
                                                    name="pwd"
                                                    value={formik.values.pwd}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                    error={
                                                        formik.touched.pwd &&
                                                        Boolean(
                                                            formik.errors.pwd
                                                        )
                                                    }
                                                    type="password"
                                                    helperText={
                                                        formik.touched
                                                            .friendName &&
                                                        formik.errors.friendName
                                                    }
                                                    sx={{ mt: 1 }}
                                                >
                                                    Password
                                                </TextField>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{ mt: 4, mb: 4, ml: 1 }}
                                    >
                                        <Grid
                                            container
                                            spacing={0}
                                            justifyContent="center"
                                        >
                                            <Grid
                                                item
                                                xs={4}
                                                sx={{ mr: 2, mt: 1 }}
                                            >
                                                <Button
                                                    variant="checkout"
                                                    type="submit"
                                                    fullWidth
                                                    disabled={
                                                        formik.isSubmitting
                                                    }
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default LoginAuthenticationCard;
