import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';
import ErrorText from 'components/errors/errorText';

import {
    setColor,
    setTimeZone,
    setDibsStudioId,
    setInstructorAltName,
    setRafAwardRedux,
    setNumDaysToShowCalendar,
    setStudioCancelTime,
    setTaxesRedux,
    setOpenTime,
    setCloseTime,
    setApptExceptions,
    setLocationIds,
    setLocationIdShowing,
    setCountry,
    setCurrencyCode,
} from 'store/slices/dibsstudio';
import { updateBasicConfigsNew } from 'actions/updateBasicConfigsNew';
import setStudioDataInStore from 'actions/setStudioDataInStore';
import checkPermissions from 'actions/studio-admin-users/checkPermissionsThisStudio';
import setCalendarDateUponLogin from 'actions/schedule/setCalendarDateUponLogin';
import setLastLogin from 'actions/users/setLastLogin';

// project imports

import useScriptRef from 'hooks/useScriptRef';
import AnimateButton from 'ui-component/extended/AnimateButton';
// import StudioEmployeeAccount from 'actions/index';

// assets
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

// import Google from 'assets/images/icons/social-google.svg';
import { useDispatch, useSelector } from 'store';
import { setThisUserDibsStudioId } from 'store/slices/studioemployeeuser';
import { setBusinessHours } from 'store/slices/dibsstudio';
import getBusinessHoursFromDb from 'actions/studios/settings/getBusinessHours';
import {
    logInWithEmailAndPassword,
    checkStatusOfUser,
    sendEmailAuthLink,
} from 'firebaseConfig';

// ============================|| FIREBASE - LOGIN ||============================ //

const FirebaseLogin = ({ loginProp, ...others }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoggedIn } = useSelector((state) => state.studioemployeeuser);
    const scriptedRef = useScriptRef();
    let { dibsId } = useParams();

    const [checked, setChecked] = React.useState(true);
    const [errorText, setErrorText] = React.useState('');
    const [hasError, setHasError] = React.useState(false);

    React.useEffect(() => {
        dispatch(setDibsStudioId(dibsId));
        dispatch(setThisUserDibsStudioId(dibsId));
    }, [dibsId, dispatch]);
    React.useEffect(() => {
        // go get the business hours for this studio
        const getbizHours = async () => {
            const bizHours = await getBusinessHoursFromDb(dibsId);

            // setBusinessHoursToUse(bizHours);
            if (bizHours?.length > 0) {
                dispatch(setBusinessHours(bizHours));
            }
        };
        getbizHours();
    }, [dibsId, dispatch]);

    React.useEffect(() => {
        const getNewData = async () => {
            await updateBasicConfigsNew(dibsId).then((res) => {
                setStudioDataInStore(res);
                dispatch(setCountry(res.country));
                dispatch(setCurrencyCode(res.currency));
                dispatch(setApptExceptions(res.apptExceptionRules));
                dispatch(setDibsStudioId(dibsId));
                dispatch(setInstructorAltName(res.instructorAltName));
                dispatch(setTimeZone(res.timezone));
                dispatch(setNumDaysToShowCalendar(res.intervalEnd));
                dispatch(setColor(res.color));
                dispatch(setRafAwardRedux(res.friendReferralAmount));
                dispatch(setOpenTime(res.locationApptsOpenTime));
                dispatch(setCloseTime(res.locationApptsCloseTime));
                dispatch(setLocationIds(res.locationIdsAll));
                dispatch(setLocationIdShowing(res.locationIdShowing));
                dispatch(setStudioCancelTime(res.cancelTime));
                dispatch(
                    setTaxesRedux({
                        salesTax: res.salesTaxRate,
                        retailTax: res.retailTaxRate,
                    })
                );
                if (dibsId === '226' || dibsId === '260') {
                    navigate(`/schedule/${dibsId}`);
                } else {
                    // send them to the schedule regardless until dashboard is built
                    navigate(`/schedule/${dibsId}`);
                }
            });
        };

        if (isLoggedIn) {
            getNewData();
        }
    }, [isLoggedIn, navigate, dibsId, dispatch]);

    const myStyle = {
        minWidth: '400px',
    };

    // const { firebaseEmailPasswordSignIn, firebaseGoogleSignIn } = useAuth();
    const firebaseEmailPasswordSignIn = async (email, password) => {
        const res = await logInWithEmailAndPassword(email, password, dibsId);
        // console.log('\n\n\nres from firebaseEmailPasswordSignIn is', res);
        return res;
    };

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                sx={{ m: 0, p: 0 }}
            >
                <Grid
                    border={0}
                    container
                    alignItems="center"
                    justifyContent="center"
                    id="login-container-form"
                >
                    <Formik
                        initialValues={{
                            // email: 'youremail@yourstudio.com',
                            email: '',
                            // password: '123456789',
                            password: '',
                            submit: null,
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email('Must be a valid email')
                                .max(255)
                                .required('Email is required'),
                            password: Yup.string()
                                .max(255)
                                .required('Password is required'),
                        })}
                        onSubmit={async (
                            values,
                            { setErrors, setStatus, setSubmitting }
                        ) => {
                            try {
                                await checkPermissions(
                                    values.email,
                                    dibsId
                                ).then(async (res) => {
                                    const {
                                        employeeId,
                                        firebaseuid,
                                        lastlogin,
                                    } = res;

                                    setCalendarDateUponLogin();
                                    if (employeeId === 0) {
                                        setErrorText(
                                            `It looks like you don't have permission to login to this admin panel. Please confirm your email address.`
                                        );
                                        setHasError(true);
                                        setTimeout(() => {
                                            setHasError(false);
                                            setErrorText('');
                                        }, 5000);
                                    } else {
                                        setHasError(false);
                                        setErrorText('');

                                        await firebaseEmailPasswordSignIn(
                                            values.email,
                                            values.password
                                        ).then(
                                            (res) => {
                                                // WARNING: do not set any formik state here as formik might be already destroyed here. You may get following error by doing so.
                                                // Warning: Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application.
                                                // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
                                                // github issue: https://github.com/formium/formik/issues/2430
                                                // console.log(
                                                setLastLogin(
                                                    values.email,
                                                    employeeId
                                                );
                                                // console.log(
                                                //     'result of the login from firebase is',
                                                //     res
                                                // );
                                            },
                                            (err) => {
                                                if (scriptedRef.current) {
                                                    setStatus({
                                                        success: false,
                                                    });
                                                    setErrors({
                                                        submit: err.message,
                                                    });
                                                    setSubmitting(false);
                                                }
                                            }
                                        );
                                    }
                                });
                            } catch (err) {
                                console.error(err);
                                if (scriptedRef.current) {
                                    setStatus({ success: false });
                                    setErrors({ submit: err.message });
                                    setSubmitting(false);
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            handleBlur,
                            handleChange,
                            handleSubmit,
                            isSubmitting,
                            touched,
                            values,
                        }) => (
                            <form
                                style={myStyle}
                                noValidate
                                onSubmit={handleSubmit}
                                {...others}
                            >
                                {hasError && <ErrorText error={errorText} />}
                                <FormControl
                                    fullWidth
                                    error={Boolean(
                                        touched.email && errors.email
                                    )}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-email-login">
                                        Email Address / Username
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-email-login"
                                        type="email"
                                        value={values.email}
                                        name="email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        label="Email Address / Username"
                                        inputProps={{}}
                                        autoComplete="email"
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText
                                            error
                                            id="standard-weight-helper-text-email-login"
                                        >
                                            {errors.email}
                                        </FormHelperText>
                                    )}
                                </FormControl>

                                <FormControl
                                    fullWidth
                                    error={Boolean(
                                        touched.password && errors.password
                                    )}
                                    sx={{ ...theme.typography.customInput }}
                                >
                                    <InputLabel htmlFor="outlined-adornment-password-login">
                                        Password
                                    </InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password-login"
                                        type={
                                            showPassword ? 'text' : 'password'
                                        }
                                        value={values.password}
                                        name="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        autoComplete="current-password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={
                                                        handleClickShowPassword
                                                    }
                                                    onMouseDown={
                                                        handleMouseDownPassword
                                                    }
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {showPassword ? (
                                                        <Visibility />
                                                    ) : (
                                                        <VisibilityOff />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                        inputProps={{}}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText
                                            error
                                            id="standard-weight-helper-text-password-login"
                                        >
                                            {errors.password}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    spacing={1}
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={checked}
                                                onChange={(event) =>
                                                    setChecked(
                                                        event.target.checked
                                                    )
                                                }
                                                name="checked"
                                                color="primary"
                                            />
                                        }
                                        label="Remember me"
                                    />
                                    <Typography
                                        variant="subtitle1"
                                        component={Link}
                                        to={`/forgot/${dibsId}`}
                                        color="secondary"
                                        sx={{ textDecoration: 'none' }}
                                    >
                                        Forgot Password?
                                    </Typography>
                                </Stack>
                                {errors.submit && (
                                    <Box sx={{ mt: 3 }}>
                                        <FormHelperText error>
                                            {errors.submit}
                                        </FormHelperText>
                                    </Box>
                                )}

                                <Box
                                    sx={{ mt: 2 }}
                                    id="submit-login-button-box"
                                >
                                    {/* <AnimateButton> */}
                                    <LoadingButton
                                        disableElevation
                                        // disabled={isSubmitting}
                                        loading={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            borderColor: `${theme.palette.primary[800]} !important`,
                                            backgroundColor: `${theme.palette.secondary[400]} !important`,
                                        }}
                                    >
                                        Sign in
                                    </LoadingButton>
                                    {/* </AnimateButton> */}
                                    <Grid
                                        item
                                        xs={12}
                                        border={0}
                                        id="link-create-new-account"
                                        sx={{ mt: 1 }}
                                    >
                                        <Grid container justifyContent="center">
                                            <Grid item id="create-new-account">
                                                <Typography
                                                    variant="subtitle1"
                                                    component={Link}
                                                    to={`/forgot/${dibsId}`}
                                                    color="secondary"
                                                    sx={{
                                                        textDecoration: 'none',
                                                    }}
                                                >
                                                    Don't have an account yet?
                                                    Click here to create a new
                                                    account.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        </>
    );
};

FirebaseLogin.propTypes = {
    loginProp: PropTypes.number,
};

export default FirebaseLogin;
