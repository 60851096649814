import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Button,
    Grid,
    Typography,
    TextField,
    InputAdornment,
    Box,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '@mui/system';

import { useFormik } from 'formik';
import * as yup from 'yup';

import createNewApptType from 'actions/appointments/createNewApptType';
import setAlert from 'actions/status/setAlert';

import { useSelector } from 'store';
import translate from 'helpers/translate';
import AutocompleteTextfield from 'ui-component/textfields/AutocompleteWithNew';

import getApptCategories from 'actions/appointments/getApptCategories';
import XIcon from 'assets/icons/XIcon';
import { dispatch } from 'store';
import { setCurrentDataTableNeedsRefresh } from 'store/slices/datatables';
import { setThisUserDibsStudioId } from 'store/slices/studioemployeeuser';

const autocompletecategories = ['Lounge', 'Hair', 'Nails', 'Spa', 'Massage'];

const StyledTextField = styled(TextField, { shouldForwardProp })({
    '& input': {
        background: 'transparent !important',
        paddingLeft: '6px !important',
        paddingTop: '4px !important',
        paddingBottom: '1px !important',
    },

    '& .MuiFormHelperText-root': {
        fontSize: '0.65rem',
        marginLeft: '2px',
        lineHeight: '0.75rem',
    },
    '& .MuiInputLabel-root': {
        paddingTop: '7px !important',
        paddingLeft: '2px !important',
    },
    '& .MuiInputAdornment-root': {
        marginRight: '0px',
        marginBottom: '0px',
    },
    '& div': {
        background: 'transparent !important',
        // paddingLeft: '2px !important',
        paddingTop: '4px !important',
        paddingBottom: '1px !important',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:before:hover': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#c96248',
    },
});

const MultiLineTextField = styled(TextField, { shouldForwardProp })({
    '& input': {
        background: 'transparent !important',
        paddingLeft: '8px !important',
        paddingTop: '4px !important',
        paddingBottom: '3px !important',
    },
    '& textarea': {
        paddingLeft: '6px !important',
        paddingBottom: '1px !important',
    },
    '& .MuiFormHelperText-root': {
        fontSize: '0.65rem',
        marginLeft: '2px',
        lineHeight: '0.75rem',
    },
    '& .MuiInputLabel-root': {
        lineHeight: '1.3rem',
    },
    '& .MuiInputAdornment-root': {
        marginRight: '0px',
        marginBottom: '3px',
    },
    '& div': {
        background: 'transparent !important',
        // paddingLeft: '2px !important',
        paddingTop: '4px !important',
        paddingBottom: '3px !important',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:before:hover': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#c96248',
    },
    '&.MuiInputBase-multiline': {
        padding: 0,
    },
});

const validationSchema = yup.object().shape({
    apptName: yup
        .string()
        .min(
            3,
            'The appointment name that you chose is too short. Please try again.'
        )
        .max(
            200,
            'The appointment name that you chose is too long. Please try again.'
        )
        .required('Appointment name is required'),
    sortIndex: yup.number().typeError('The sort index must be a number'),
    // defaultPrice: yup
    //     .number()
    //     .typeError('The price must be a number')
    //     .min(0, 'The price must be at least $0')
    //     .required('A price is required'),
    defaultPrice: yup
        .string()
        .test(
            'is-valid-currency',
            'The price must be a valid currency amount',
            (value) => {
                if (!value) return false; // Required validation
                try {
                    // Remove localized formatting (e.g., commas, spaces)
                    const cleanedValue = value.replace(/[^\d.-]/g, '');
                    const parsed = parseFloat(cleanedValue);
                    // Ensure parsed value is a valid number
                    return !isNaN(parsed) && parsed >= 0;
                } catch {
                    return false;
                }
            }
        )
        .required('A price is required'),
    length: yup
        .number()
        .typeError('The length in minutes must be a number')
        .required('The length is required'),
    description: yup
        .string()
        .min(
            3,
            'The description information that you entered is too short. Please try again.'
        ),
    serviceCategory: yup
        .string()
        .min(
            3,
            'The service category that you chose is too short. Please try again.'
        ),
});

const CreateNewAppt = ({ setShowCreateNewAppt }) => {
    const { dibsId } = useParams();
    console.log('line 178 - dibsId is', dibsId);
    useEffect(() => {
        if (dibsId) {
            dispatch(setThisUserDibsStudioId(dibsId));
        }
    }, [dibsId]);

    const [canApplyPackages, setCanApplyPackages] = useState(true);
    const [justSubmittedAppt, setJustSubmittedAppt] = useState(false);

    const [showApplyPackages, setShowApplyPackages] = useState(true);
    const [apptCategories, setApptCategories] = useState(
        autocompletecategories
    );
    const [currencySymbol, setCurrencySymbol] = useState('$');

    const { dibsStudioId, studioEmployeeId } = useSelector(
        (state) => state.studioemployeeuser
    );
    const studioemployeUser = useSelector((state) => state.studioemployeeuser);
    console.log(
        '\n\n\ncreating new appt - dibsStudioId is',
        dibsStudioId,
        studioEmployeeId
    );
    console.log('full value of studio employee user is', studioemployeUser);
    const { country, currencyCode } = useSelector(
        (state) => state.dibsstudio.config
    );

    useEffect(() => {
        if (currencyCode) {
            const getCurrencySymbol = (currencyCode) => {
                const currencyFormat = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: currencyCode,
                }).formatToParts(0);

                // Find the part where type is 'currency' and return its value
                const currencyPart = currencyFormat.find(
                    (part) => part.type === 'currency'
                );
                return currencyPart ? currencyPart.value : null;
            };
            const symb = getCurrencySymbol(currencyCode);
            if (symb) setCurrencySymbol(symb);
            else setCurrencySymbol('$');
        }
    }, [currencyCode]);

    useEffect(() => {
        const categories = async () => {
            const res = await getApptCategories(dibsStudioId);
            const { data, success } = res;
            if (success) {
                setApptCategories(data);
            } else {
                console.log('had an error getting the appt categories');
            }
        };
        categories();
    }, [dibsStudioId]);

    const formik = useFormik({
        initialValues: {
            apptName: '',
            defaultPrice: '',
            length: '',
            description: '',
            serviceCategory: '',
            sortIndex: '',
        },
        validationSchema,
        onSubmit: async (
            values,
            { setErrors, setStatus, setSubmitting, resetForm }
        ) => {
            try {
                setJustSubmittedAppt(false);
                const valuestosend = {
                    ...values,
                    canApplyPackages,
                    dibsId: dibsStudioId,
                };
                const response = await createNewApptType(valuestosend);

                const { success } = response;
                if (success) {
                    resetForm();
                    setJustSubmittedAppt(true);

                    setTimeout(() => {
                        setJustSubmittedAppt(false);
                    }, 5000);
                    setAlert({
                        msg: `Appointment type has been created! If you want to add more appointments, you can do so now. Otherwise, click 'Done' to close this panel.`,
                        success: true,
                        seconds: 7,
                    });
                    dispatch(setCurrentDataTableNeedsRefresh(true));
                } else {
                    setAlert({
                        msg: `There was an error creating the appointment. Please refresh your screen and try again. Please contact support if the problem persists`,
                        error: true,
                        seconds: 7,
                    });
                }
            } catch (error) {
                setStatus({ success: false });
                setErrors({ submit: error.message });
                setSubmitting(false);
                setAlert({
                    msg: `There was an error creating the appointment. Please refresh your screen and try again. Please contact support if the problem persists`,
                    error: true,
                    seconds: 7,
                });
            }
        },
    });

    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260') {
            setShowApplyPackages(false);
        }
    }, [dibsStudioId]);
    const customHandleChange = (e) => {
        // Call Formik's handleChange
        formik.handleChange(e);

        // Change the cancel button text back to "Cancel"
        setJustSubmittedAppt(false);
    };
    const customHandleChangeService = (e) => {
        // Call Formik's handleChange
        formik.handleChange(e);

        // Change the cancel button text back to "Cancel"
        setJustSubmittedAppt(false);
    };
    const handleChangeInDropDown = (e, fieldtochange) => {
        // Call Formik's handleChange
        formik.setFieldValue(fieldtochange, e);

        // Change the cancel button text back to "Cancel"
        setJustSubmittedAppt(false);
    };
    const addnewapptT = translate({
        id: 'add-new-appt',
        msg: 'Add New Appointment',
    });
    const createNewApptT = translate({
        id: 'create-a-new-appointment',
        msg: 'Create A New Appointment Type',
    });
    const apptNameLabel = translate({
        id: 'appointment-name',
        msg: 'Appointment Name',
    });
    const lengthInMinutes = translate({
        id: 'lengthoftime',
        msg: 'Length in mins',
    });
    const defaultprice = translate({
        id: 'default-price',
        msg: 'Default Price',
    });
    const serviceCategory = translate({
        id: 'service-category',
        msg: 'Service Category',
    });
    const description = translate({
        id: 'description',
        msg: 'Description',
    });
    const sortIndex = translate({
        id: 'sort-index',
        msg: 'Sort Index (Optional)',
    });
    const setOrderAppearance = translate({
        id: 'set-order-help',
        msg: 'Set the order of appearance (optional)',
    });
    const cancelT = translate({
        id: 'cancel',
        msg: 'Cancel',
    });

    return (
        <Grid container>
            <Grid
                item
                border={0}
                xs={9}
                md={7}
                lg={6}
                sx={{ mt: 2, mb: 1, ml: 0.2 }}
                id="create-new-appt-title"
            >
                <Typography gutterBottom variant="h4">
                    {createNewApptT}
                </Typography>
            </Grid>
            <Grid
                item
                border={0}
                xs={1}
                sx={{ mt: 1.75, mb: 1, ml: 0.2, cursor: 'pointer' }}
                id="close-create-new-appt-btn-grid"
                onClick={() => {
                    setShowCreateNewAppt(false);
                }}
            >
                <XIcon color={`a09d9d`} />
            </Grid>
            <Grid item xs={12} border={0}>
                <Box sx={{ width: '100%' }}>
                    <form
                        className="form-normal-dibs"
                        onSubmit={formik.handleSubmit}
                    >
                        <Grid
                            item
                            xs={12}
                            border={0}
                            sx={{ ml: 0.5 }}
                            id="form-add-name-appt"
                        >
                            <Grid container>
                                <Grid
                                    xs={12}
                                    md={6}
                                    lg={5}
                                    xl={5}
                                    item
                                    sx={{ mt: 0 }}
                                    id="name-appt"
                                >
                                    <StyledTextField
                                        fullWidth
                                        name="apptName"
                                        autoComplete="off"
                                        variant="standard"
                                        value={formik.values.apptName}
                                        onChange={customHandleChange}
                                        onBlur={formik.handleBlur}
                                        label={apptNameLabel}
                                        id="new-appt-type-name"
                                        placeholder="Enter a name for the appointment"
                                        size="small"
                                        error={
                                            justSubmittedAppt &&
                                            Boolean(formik.errors.apptName)
                                        }
                                        helperText={formik.errors.apptName}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ ml: 0.5, mt: 2 }}
                            border={0}
                            id="default-price-for-appt"
                        >
                            <Grid container justifyContent="flex-start">
                                <Grid
                                    id="length-mins-item"
                                    item
                                    sx={{ mt: 1, mr: 4 }}
                                >
                                    <StyledTextField
                                        label={lengthInMinutes}
                                        id="new-appt-length"
                                        name="length"
                                        placeholder="60"
                                        size="small"
                                        variant="standard"
                                        value={formik.values?.length}
                                        onChange={customHandleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{ width: '110px' }}
                                        error={
                                            justSubmittedAppt &&
                                            // formik.touched?.length &&
                                            Boolean(formik.errors?.length)
                                        }
                                        helperText={formik.errors?.length}
                                    />
                                </Grid>
                                <Grid
                                    id="default-price-for-appt-item"
                                    item
                                    sx={{ mt: 1 }}
                                >
                                    <StyledTextField
                                        label={defaultprice}
                                        name="defaultPrice"
                                        variant="standard"
                                        id="new-appt-default-price"
                                        value={formik.values.defaultPrice}
                                        onChange={customHandleChange}
                                        onBlur={formik.handleBlur}
                                        size="small"
                                        sx={{ width: '100px' }}
                                        error={
                                            justSubmittedAppt &&
                                            Boolean(formik.errors.defaultPrice)
                                        }
                                        helperText={formik.errors.defaultPrice}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Box
                                                        sx={{ ml: 0.5 }}
                                                        border={0}
                                                    >
                                                        {currencySymbol}
                                                    </Box>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ ml: 0.5, mt: 1 }}
                            id="new-service-category"
                        >
                            <Grid container justifyContent="flex-start">
                                <Grid
                                    xs={12}
                                    md={5}
                                    lg={3}
                                    xl={3}
                                    item
                                    sx={{ mt: 1 }}
                                    border={0}
                                >
                                    <AutocompleteTextfield
                                        idtouse="new-appt-service-category"
                                        titletouse={serviceCategory}
                                        fullWidth
                                        setArrayPlusNewOption={
                                            setApptCategories
                                        }
                                        customHandleChange={
                                            customHandleChangeService
                                        }
                                        handleChangeInDropDown={
                                            handleChangeInDropDown
                                        }
                                        optionsPassedIn={apptCategories}
                                        varianttouse="standard"
                                        size="small"
                                        name="serviceCategory"
                                        value={formik.values.serviceCategory}
                                        onChange={customHandleChangeService}
                                        onBlur={formik.handleBlur}
                                        error={
                                            justSubmittedAppt &&
                                            // formik.touched.serviceCategory &&
                                            Boolean(
                                                formik.errors.serviceCategory
                                            )
                                        }
                                        helperText={
                                            // formik.touched.serviceCategory &&
                                            formik.errors.serviceCategory
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            border={0}
                            sx={{ ml: 0.5, mt: 1 }}
                            id="form-add-description"
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    md={5}
                                    lg={5}
                                    xl={3}
                                    sx={{ mt: 2 }}
                                    border={0}
                                    id="description-appt"
                                >
                                    <MultiLineTextField
                                        id="new-appt-description"
                                        label={description}
                                        autoComplete="off"
                                        name="description"
                                        placeholder="Enter a description for the appointment"
                                        variant="standard"
                                        varianttouse="standard"
                                        fullWidth
                                        value={formik.values.description}
                                        onChange={customHandleChange}
                                        onBlur={formik.handleBlur}
                                        multiline
                                        maxRows={4}
                                        size="small"
                                        error={
                                            justSubmittedAppt &&
                                            // formik.touched.description &&
                                            Boolean(formik.errors.description)
                                        }
                                        helperText={
                                            // formik.touched.description &&
                                            formik.errors.description
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{ ml: 0.5, mt: 2 }}
                            id="appt-sort-in"
                        >
                            <Grid container justifyContent="flex-start">
                                <Grid
                                    item
                                    id="sort-index-item"
                                    sx={{ mb: 2, width: '230px' }}
                                    border={0}
                                >
                                    <StyledTextField
                                        id="new-appt-sort-index"
                                        label={sortIndex} // add a helper icon eventually
                                        fullWidth
                                        name="sortIndex"
                                        value={formik.values.sortIndex}
                                        onChange={customHandleChange}
                                        onBlur={formik.handleBlur}
                                        size="small"
                                        variant="standard"
                                        helperText={
                                            formik.errors.sortIndex
                                                ? formik.errors.sortIndex // Display the error message from Yup validation
                                                : setOrderAppearance // Default helper text when there's no error
                                        }
                                        error={formik.errors.sortIndex}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        {showApplyPackages && (
                            <Grid
                                item
                                xs={12}
                                sx={{ ml: 0.5, mt: 3 }}
                                id="checkbox-show-apply-packages"
                            >
                                <Grid container justifyContent="flex-start">
                                    <Grid item>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        onClick={() => {
                                                            setCanApplyPackages(
                                                                !canApplyPackages
                                                            );
                                                        }}
                                                    />
                                                }
                                                label="Can apply packages"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            item
                            xs={12}
                            sx={{ ml: 0.5, mt: 3, mb: 3 }}
                            id="submit-section-buttons"
                        >
                            {justSubmittedAppt && (
                                <Button
                                    variant="greenconfirm"
                                    onClick={() => {
                                        setShowCreateNewAppt(false);
                                    }}
                                    // sx={{ width: '100px' }}
                                >
                                    {`Done! Close Panel`}
                                </Button>
                            )}
                            {!justSubmittedAppt && (
                                <>
                                    <Button
                                        variant="cancel"
                                        onClick={() => {
                                            setShowCreateNewAppt(false);
                                        }}
                                    >
                                        {cancelT}
                                    </Button>
                                </>
                            )}
                            <Button
                                id="submit-new-appt"
                                variant="submit"
                                type="submit"
                                sx={{ ml: 2 }}
                                disabled={formik.isSubmitting}
                            >
                                {addnewapptT}
                            </Button>
                        </Grid>
                    </form>
                </Box>
            </Grid>
        </Grid>
    );
};

export default CreateNewAppt;
