import * as React from 'react';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '@mui/system';
import { Typography } from '@mui/material';

const filter = createFilterOptions();

const StyledTextField = styled(TextField, { shouldForwardProp })({
    '& input': {
        background: 'transparent !important',
        paddingLeft: '6px !important',
        paddingTop: '4px !important',
        paddingBottom: '0px !important',
    },
    '& .MuiFormHelperText-root': {
        fontSize: '0.65rem',
        marginLeft: '2px',
        lineHeight: '0.75rem',
    },
    '& .MuiTextField-root': {
        '& input': {
            background: 'transparent !important',
            paddingLeft: '8px !important',
            paddingTop: '4px !important',
            paddingBottom: '3px !important',
            marginBottom: '50px !important',
        },
    },

    '& .MuiInputLabel-root': {
        paddingTop: '6px !important',
        paddingLeft: '2px !important',
    },
    '& .MuiInputAdornment-root': {
        marginRight: '0px',
        marginBottom: '3px',
    },
    '& div': {
        background: 'transparent !important',
        // paddingLeft: '2px !important',
        paddingTop: '4px !important',
        paddingBottom: '3px !important',
    },
    '& .MuiInput-underline:before': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:before:hover': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#c96248',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#c96248',
    },
});

export default function AutocompleteWithNewOption({
    idtouse,
    titletouse,
    varianttouse,
    customHandleChange,
    handleChangeInDropDown,
    optionsPassedIn,
    setArrayPlusNewOption,
    value,
    error,
    helperText,
}) {
    const [open, toggleOpen] = React.useState(false);

    const valuepassin = {
        title: value || '',
        category: value || '',
        key: `${value}-${Math.random()}`,
    };

    const handleClose = () => {
        setDialogValue({
            title: '',
        });
        toggleOpen(false);
    };
    const updateOptionSet = (newOption) => {
        const newoptionObject = {
            key: `${newOption.inputValue}-${Math.random()}`,
            title: newOption.inputValue,
            category: newOption.inputValue,
        };
        const newArray = [...optionsPassedIn, newoptionObject];
        setArrayPlusNewOption(newArray);
    };

    const [dialogValue, setDialogValue] = React.useState({
        title: '',
    });

    const handleSubmit = (event) => {
        event.preventDefault();
        // setValue({
        //     title: dialogValue.title,
        // });
        handleClose();
    };

    return (
        <React.Fragment>
            <Autocomplete
                value={valuepassin}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        console.log('string gets called');
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            // toggleOpen(true);
                            setDialogValue({
                                title: newValue,
                            });
                        });
                    } else if (newValue && newValue?.inputValue) {
                        // toggleOpen(true);
                        updateOptionSet(newValue);
                        setDialogValue({
                            title: newValue?.inputValue,
                        });
                        //  customHandleChange(newValue?.inputValue);
                        handleChangeInDropDown(
                            newValue?.inputValue,
                            'serviceCategory'
                        );
                        // setValue(newValue);
                    } else {
                        // setValue(newValue);
                        if (newValue) {
                            handleChangeInDropDown(
                                newValue?.category,
                                'serviceCategory'
                            );
                        } else {
                            handleChangeInDropDown('', 'serviceCategory');
                        }
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue.toUpperCase(),
                            title: `Add "${params.inputValue.toUpperCase()}" as a new category`,
                        });
                    }

                    return filtered;
                }}
                id={idtouse}
                options={optionsPassedIn}
                getOptionLabel={(option) => {
                    // for example value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        const optUc = option.inputValue.toUpperCase();

                        return optUc;
                    }
                    return option.title;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => {
                    const { key, ...otherProps } = props;
                    const randomKey = Math.random();
                    return (
                        <li key={`${key}-${randomKey}`} {...otherProps}>
                            {option.title}
                        </li>
                    );
                }}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => {
                    return (
                        <StyledTextField
                            variant={varianttouse}
                            {...params}
                            label={titletouse}
                        />
                    );
                }}
            />
            <Typography variant="redSubtext" sx={{ mt: 0.25 }}>
                {helperText}
            </Typography>
            <Dialog open={open} onClose={handleClose}>
                <form onSubmit={handleSubmit}>
                    <DialogTitle>Add a new film</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Did you miss any film in our list? Please, add it!
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            value={dialogValue.title}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    title: event.target.value,
                                })
                            }
                            label="title"
                            type="text"
                            variant="standard"
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            value={dialogValue.year}
                            onChange={(event) =>
                                setDialogValue({
                                    ...dialogValue,
                                    year: event.target.value,
                                })
                            }
                            label="year"
                            type="number"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </React.Fragment>
    );
}
