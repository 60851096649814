import axios from 'axios';
import { getApiUrl } from 'helpers';

export default async function createNewApptType(valuestosend) {
    console.log(
        '\n\n\n\nvalues to send are',
        JSON.stringify(valuestosend, null, 4)
    );
    const { defaultPrice } = valuestosend;
    const normalizedValue = defaultPrice.replace(',', '.');
    const newval = parseFloat(normalizedValue);
    console.log('new val is', newval);
    const newvs = {
        ...valuestosend,
        defaultPrice: newval,
    };

    const url = getApiUrl('/appts/create-new-appt-type');

    const res = await axios.post(url, newvs);
    console.log('res in api call is', res);
    const { data } = res;
    if (data) {
        return {
            success: true,
            data,
        };
    } else {
        return {
            error: true,
            message: 'There was an error creating the appointment type',
        };
    }
}
