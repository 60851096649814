import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const phoneUtil =
    require('google-libphonenumber').PhoneNumberUtil.getInstance();
const PNF = require('google-libphonenumber').PhoneNumberFormat;

export const capitalizeString = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const capitalizeEachWord = (str) => {
    if (!str) return '';
    return str.split(' ').map(capitalizeString).join(' ');
};
export const lowercaseString = (str) => {
    // return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    if (!str) return '';
    let result = str.toLowerCase();
    return result;
};
export const getLightRandomHexColor = () => {
    // Helper function to generate a random integer within a given range
    function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Generate each color component within the range of 128 to 255 to ensure lightness
    const red = getRandomInt(120, 255);
    const green = getRandomInt(120, 255);
    const blue = getRandomInt(120, 255);

    // Convert the numbers to a hex string, ensuring they are 2 characters long
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    // Concatenate the hex strings
    return '#' + redHex + greenHex + blueHex;
};

export const capitalizeEntireString = (str) => {
    if (!str) return '';
    let result = str.toUpperCase();
    return result;
};
export const getApiUrl = (path) => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    // console.log('\n\n\nbaseURL is', baseURL);
    const basicurl = `${baseURL}${path}`;
    return basicurl;
};
export const convertEpochNormal = async (value) => {
    const newdate = new Date(Number(value));
    if (isNaN(newdate.valueOf())) {
        return '';
    }
    const day = newdate.getDate();

    const mth = newdate.getMonth() + 1;
    const year = newdate.getFullYear();
    const shortyear = year.toString().substring(2, 4);
    return `${mth}/${day}/${shortyear}`;
};
export const convertEpoch = async (value) => {
    // if (!value) {
    //     return '';
    // }
    const newdate = new Date(Number(value) * 1000);
    if (isNaN(newdate.valueOf())) {
        return '';
    }
    const day = newdate.getDate();

    const mth = newdate.getMonth() + 1;
    const year = newdate.getFullYear();
    const shortyear = year.toString().substring(2, 4);
    return `${mth}/${day}/${shortyear}`;
};

export const differenceBetweenTimes = (t1, t2) => {
    const t1time = new Date(t1);
    const t2time = new Date(t2);
    var diff = (t1time.getTime() - t2time.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
};

export const getToken = async () => {
    const dibsToken = localStorage.getItem('dibs-token');

    return dibsToken;
};
export const getHeaders = async () => {
    const dibsToken = await getToken();
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${dibsToken}`,
    };
    return headers;
};
export const getTimeUTCDisplay = async (thisdate) => {
    return dayjs.utc(thisdate).format('H:mm a');
};

export const formatPhoneNumber = async (phoneNumberString) => {
    const number = phoneUtil.parse(phoneNumberString, 'US');
    const valid = phoneUtil.isPossibleNumber(number);
    const phoneString = phoneUtil.format(number, PNF.INTERNATIONAL);
    const msg = {
        valid,
        phoneString,
    };
    return msg;
};
export const calculatecredit = async (credit, total) => {
    if (credit > total) {
        return total;
    } else {
        return credit;
    }
};
export const calculateDiscountedTax = async (iteminfo) => {
    const tax = iteminfo.discountedprice * (iteminfo.taxAmount / 100);
    return roundtoN(tax, 2);
};
export const formatDollarAmountTwoDigits = (dollarAmount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(dollarAmount);
};
export const formatDollarAmountTwoDigitsCountry = (
    dollarAmount,
    countryCode,
    currency = 'USD'
) => {
    const hasCents = Math.floor(dollarAmount) !== dollarAmount;

    return new Intl.NumberFormat(countryCode, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2, // Always show up to 2 decimal places
        minimumFractionDigits: hasCents ? 2 : 0, // Show decimals only if there are cents
    }).format(dollarAmount);
};

export const roundtoN = (n, digits) => {
    if (digits === undefined) {
        digits = 0;
    }

    var multiplicator = Math.pow(10, digits);
    n = parseFloat((n * multiplicator).toFixed(11));
    return Math.round(n) / multiplicator;
};

export const formatDollarAmount = (dollarAmount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    }).format(dollarAmount);
};

export const formatDollarNew = (amount) => {
    let formatted = amount.toFixed(2);

    // Convert the formatted string back to a float and check if the decimal part is 0
    if (parseFloat(formatted) === Math.floor(amount)) {
        // If there's no decimal part or it's 0, return as an integer
        return `$${Math.floor(amount)}`;
    } else {
        // If there's a decimal part greater than 0, return with two decimal places
        return `$${formatted}`;
    }
};

export const formatDollarAmountDigits = (dollarAmount) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    }).format(dollarAmount);
};

const formattedTime = (datePassed) => {
    const datep = `${datePassed}`;
    const date = new Date(datep);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    if (hours12 === 0) hours12 = 12;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours12}:${minutesFormatted} ${ampm}`;
    return time;
};

export const breakupDate = async (datetouse) => {
    const datep = `${datetouse}`;
    const dateObj = new Date(datep);
    const month = dateObj.getUTCMonth() + 1; // months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const shortyear = year.toString().substring(2, 4);
    let formatteddate = `${month}/${day}/${shortyear}`;
    const formattedtime = formattedTime(datep);
    const msg = {
        formatteddate,
        formattedtime,
    };
    return msg;
};
export const displayDate = async (date) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const dayOfWeek = dateObj.toLocaleString('en-us', { weekday: 'long' });
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${dayOfWeek}, ${month} ${day}, ${year}`;
    return formattedDate;
};

export const getClassesText = (numClasses) => {
    if (numClasses === 1) {
        return '1 class';
    } else if (numClasses >= 999) {
        return 'Unlimited classes';
    } else {
        return `${numClasses} classes`;
    }
};
export const getExpiresText = (validity, increment, commitment, autopay) => {
    let incrementText = increment;
    if (validity > 1) {
        incrementText = `${increment}s`;
    }
    let expiresText = `Expires ${validity} ${incrementText} from purchase date`;
    if (autopay === 'FORCE' || autopay === 'ALLOW') {
        expiresText = `Renews ${validity} ${incrementText} after purchase date`;
    }
    if (commitment > 0) {
        expiresText = `Renews ${increment}ly. \n ${commitment} ${increment} minimum commitment`;
    }
    return expiresText;
};
export const getCreditToApply = (credit, total) => {
    if (credit > total) {
        return total;
    } else {
        return credit;
    }
};
export const calculatePricePerClass = (
    price,
    numClasses,
    pricePerClassOverride
) => {
    const pricePerClass = price / numClasses;
    let p = 10;
    if (Number.isInteger(pricePerClass)) p = pricePerClass;
    else p = pricePerClass.toFixed(2);
    if (pricePerClassOverride) return '($' + pricePerClassOverride + '/class)';
    return '($' + p + '/class)';
};
export const calculateDifferenceDate = async (
    startDateEpoch,
    endsDateEpoch,
    period,
    value
) => {
    const starting = await convertEpoch(startDateEpoch);
    const ending = await convertEpoch(endsDateEpoch);
    const began = new Date(starting);
    let minEndTime;
    if (period === 'month') {
        minEndTime = began.setMonth(began.getMonth() + value);
    }
    const minEnd = await convertEpochNormal(minEndTime);
    if (ending >= minEnd) {
        return true;
    }
    return false;
};

export default capitalizeString;
