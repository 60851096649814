import { memo, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

// material-ui
import { Typography } from '@mui/material';

// project imports
import { useSelector } from 'store';
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import {
    // dibsSuperAdminMenuItems,
    hairStylistMenuItems,
    // employeeMenu,
    // instructorMenu,
    // studioAdminMenu,
} from 'menu-items';

// ==============================|| SIDEBAR MENU LIST - THIS ONE IS BEING USED ||============================== //

const MenuList = () => {
    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);
    const [menuItems, setMenuItems] = useState(menuItem);
    const [navToUse, setNavToUse] = useState(null);

    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260') {
            setMenuItems(hairStylistMenuItems);
        }
    }, [dibsStudioId]);
    useEffect(() => {
        const navItems = menuItems.items.map((item) => {
            switch (item.type) {
                case 'group':
                    return (
                        <NavGroup
                            key={item.id}
                            item={item}
                            dibsId={dibsStudioId}
                        />
                    );
                default:
                    return (
                        <Typography
                            key={item.id}
                            variant="h6"
                            // color="error"
                            align="center"
                        >
                            Menu Items
                        </Typography>
                    );
            }
        });
        setNavToUse(navItems);
    }, [menuItems, dibsStudioId]);

    return <>{navToUse}</>;
};

export default memo(MenuList);
